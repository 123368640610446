import {map} from 'lodash'
import S from 'fluent-json-schema'
import * as megashopAttributeTypes from 'common/constants/megashop/megashopAttributeTypes'
import * as baseSchemas from '../../baseSchemas'


export const presentationSchema = {
  selectBox: S.boolean(),
  label: baseSchemas.standardString(),
  unit: baseSchemas.standardString(),
  decimalPlaces: baseSchemas.nullable(S.integer()),
  halfWidth: S.boolean().default(false),
  list: {
    enabled: S.boolean(),
    label: baseSchemas.standardString(),
    order: baseSchemas.positiveInteger(),
  },
  detail: {
    enabled: S.boolean(),
    label: baseSchemas.standardString(),
    order: baseSchemas.positiveInteger(),
  },
  imageSize: baseSchemas.imageSizeType(),
  bold: S.boolean(),
}

export const filterSchema = {
  enabled: S.boolean(),
  label: baseSchemas.standardString(),
  order: baseSchemas.positiveInteger(),
  tolerance: S.number(),
  hideOnChildren: S.boolean().default(false),
}

export const validationSchema = {
  limit: baseSchemas.positiveInteger(),
  required: S.boolean(),
  minValue: S.integer(),
  maxValue: S.integer(),
  isFloat: S.boolean(),
  hasAttachment: S.boolean().default(false),
  dimension: baseSchemas.dimension(),
}

export const enumValue = {
  value: baseSchemas.standardString().required(),
  sealId: baseSchemas.id().required(),
  sealName: baseSchemas.standardString(), // Maybe not required
  materialId: baseSchemas.id().required(),
  materialName: baseSchemas.standardString(), // Maybe not required
  imageId: baseSchemas.nullable(baseSchemas.uuid()),
}

export const ATTRIBUTE_STRING = {
  type: megashopAttributeTypes.STRING,
  value: baseSchemas.standardString(),
  schema: (meta) => S.string().maxLength(meta?.validation?.limit || 255),
  meta: baseSchemas.object({
    presentation: baseSchemas.object({
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      limit: validationSchema.limit,
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const ATTRIBUTE_TEXT = {
  type: megashopAttributeTypes.TEXT,
  value: S.string(),
  schema: (meta) => S.string().maxLength(meta?.validation?.limit || 65535),
  meta: baseSchemas.object({
    presentation: baseSchemas.object({
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      limit: validationSchema.limit,
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const ATTRIBUTE_INTEGER = {
  type: megashopAttributeTypes.INTEGER,
  value: S.integer(),
  schema: (meta) => S.integer()
    .minimum(meta?.validation?.minValue || Number.MIN_SAFE_INTEGER)
    .maximum(meta?.validation?.maxValue || Number.MAX_SAFE_INTEGER),
  meta: baseSchemas.object({
    presentation: baseSchemas.object({
      unit: presentationSchema.unit,
      label: presentationSchema.label,
      decimalPlaces: presentationSchema.decimalPlaces,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      tolerance: filterSchema.tolerance,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      minValue: validationSchema.minValue,
      maxValue: validationSchema.maxValue,
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const ATTRIBUTE_NUMBER = {
  type: megashopAttributeTypes.NUMBER,
  value: S.number(),
  schema: (meta) => S.number()
    .minimum(meta?.validation?.minValue || Number.MIN_SAFE_INTEGER)
    .maximum(meta?.validation?.maxValue || Number.MAX_SAFE_INTEGER),
  meta: baseSchemas.object({
    presentation: baseSchemas.object({
      unit: presentationSchema.unit,
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      decimalPlaces: presentationSchema.decimalPlaces,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      tolerance: filterSchema.tolerance,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      minValue: validationSchema.minValue,
      maxValue: validationSchema.maxValue,
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}
export const ATTRIBUTE_ENUM = {
  type: megashopAttributeTypes.ENUM,
  value: S.string(),
  schema: (meta) => S.string().enum(map(meta.enumValues, 'value')),
  meta: baseSchemas.object({
    enumValues: baseSchemas.collection({
      value: enumValue.value,
      imageId: enumValue.imageId,
    }).required().minItems(1),
    presentation: baseSchemas.object({
      selectBox: presentationSchema.selectBox,
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      imageSize: presentationSchema.imageSize,
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}
export const ATTRIBUTE_ENUM_ARRAY = {
  type: megashopAttributeTypes.ENUM_ARRAY,
  value: S.array().items(S.string()),
  schema: (meta) => baseSchemas.array(S.string().enum(map(meta.enumValues, 'value'))),
  meta: baseSchemas.object({
    enumValues: baseSchemas.collection({
      value: enumValue.value,
      imageId: enumValue.imageId,
    }).required().minItems(1),
    presentation: baseSchemas.object({
      selectBox: presentationSchema.selectBox,
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      imageSize: presentationSchema.imageSize,
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const MEGASEAL_MATERIAL = {
  type: megashopAttributeTypes.MEGASEAL_MATERIAL,
  value: baseSchemas.id(),
  schema: (meta) => baseSchemas.id().enum(map(meta.enumValues, 'materialId')),
  meta: baseSchemas.object({
    componentId: baseSchemas.id().required(),
    enumValues: baseSchemas.collection({
      materialId: enumValue.materialId,
      materialName: enumValue.materialName,
      imageId: enumValue.imageId,
    }).required().minItems(1),
    presentation: baseSchemas.object({
      selectBox: presentationSchema.selectBox,
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      imageSize: presentationSchema.imageSize,
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const MEGASEAL_SEAL = {
  type: megashopAttributeTypes.MEGASEAL_SEAL,
  value: baseSchemas.id(),
  schema: (meta) => baseSchemas.id().enum(map(meta.enumValues, 'sealId')),
  meta: baseSchemas.object({
    enumValues: baseSchemas.collection({
      sealId: enumValue.sealId,
      sealName: enumValue.sealName,
      imageId: enumValue.imageId,
    }).required().minItems(1),
    presentation: baseSchemas.object({
      selectBox: presentationSchema.selectBox,
      label: presentationSchema.label,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      imageSize: presentationSchema.imageSize,
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const MEGASEAL_DIMENSION = {
  type: megashopAttributeTypes.MEGASEAL_DIMENSION,
  value: S.number(),
  schema: (meta) => S.number()
    .minimum(meta?.validation?.minValue || Number.MIN_SAFE_INTEGER)
    .maximum(meta?.validation?.maxValue || Number.MAX_SAFE_INTEGER),
  meta: baseSchemas.object({
    dimension: validationSchema.dimension,
    presentation: baseSchemas.object({
      unit: presentationSchema.unit,
      label: presentationSchema.label,
      decimalPlaces: presentationSchema.decimalPlaces,
      halfWidth: presentationSchema.halfWidth,
      list: baseSchemas.object({
        enabled: presentationSchema.list.enabled,
        label: presentationSchema.list.label,
        order: presentationSchema.list.order,
      }),
      detail: baseSchemas.object({
        enabled: presentationSchema.detail.enabled,
        label: presentationSchema.detail.label,
        order: presentationSchema.detail.order,
      }),
      bold: presentationSchema.bold,
    }),
    filter: baseSchemas.object({
      enabled: filterSchema.enabled,
      label: filterSchema.label,
      order: filterSchema.order,
      hideOnChildren: filterSchema.hideOnChildren,
    }),
    validation: baseSchemas.object({
      minValue: validationSchema.minValue,
      maxValue: validationSchema.maxValue,
      required: validationSchema.required,
      hasAttachment: validationSchema.hasAttachment,
    }),
  }),
}

export const ALL_ATTRIBUTES = [
  ATTRIBUTE_STRING,
  ATTRIBUTE_TEXT,
  ATTRIBUTE_INTEGER,
  ATTRIBUTE_NUMBER,
  ATTRIBUTE_ENUM,
  ATTRIBUTE_ENUM_ARRAY,
  MEGASEAL_MATERIAL,
  MEGASEAL_SEAL,
  MEGASEAL_DIMENSION,
]
