import Ajv from 'ajv'
import addFormats from 'ajv-formats'
import localize from 'ajv-i18n/localize/sk'
import {cloneDeep, get, set, isEmpty, isString, isArray, keyBy, map, filter, isNil} from 'lodash'
import {ARRAY_ERROR} from 'final-form'
import S from 'fluent-json-schema'
import {ALL_CONTENT} from '../../srv/src/common/apps/megashop/content'
import * as baseSchemas from '../../srv/src/common/baseSchemas'
import * as megashopSchemas from '../../srv/src/common/apps/megashop/schemas'
import {ALL_ATTRIBUTES} from '../../srv/src/common/apps/megashop/attributes'


export const showErrorOnChange = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched, modified}, helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && (touched || modified))
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}

export const showErrorOnBlur = ({
  meta: {submitError, dirtySinceLastSubmit, error, touched}, helperText,
}) => {
  const isError = Boolean(((submitError && !dirtySinceLastSubmit) || error) && touched)
  const helperTextOrError = isError ? error || submitError : helperText

  return {isError, helperTextOrError}
}

const cache = new Map()
export const cacheSchema = (schema) => {
  const key = JSON.stringify(schema.valueOf({isRoot: false}))
  return cache.get(key) || cache.set(key, schema).get(key)
}

const options = {
  coerceTypes: 'array',
  useDefaults: true,
  validateFormats: 'full',
  verbose: true,
  strict: false,
  allErrors: true,
  removeAdditional: true,
}
const ajv = new Ajv(options)
addFormats(ajv)

const schemaCache = new WeakMap()

const errorMessage = (error) => error.message

export const validator = (schema, opts = {}) => {
  const options = {
    ajv,
    errorMessage,
    localize,
    ...opts,
  }

  return (passedValues) => {
    const values = cloneDeep(passedValues)
    const validate = schemaCache.get(schema) || schemaCache.set(schema, ajv.compile(schema.valueOf({isRoot: false}))).get(schema)
    const valid = validate(values)

    const errors = {}
    if (!valid) {
      options.localize(validate.errors)

      validate.errors.forEach((_error) => {
        const errorElements = _error.params.errors ? _error.params.errors : [_error]

        errorElements.forEach((error) => {
          const rootPath = error.instancePath
          const property = error.params.missingProperty ? `/${error.params.missingProperty}` : ''
          let fullPath = `${rootPath}${property}`.replace(/\//g, '.').substring(1)

          if (error.parentSchema?.type === 'array') {
            // Empty array must be kept as array for final form to work
            if (!isArray(get(errors, fullPath))) set(errors, fullPath, [])
            fullPath += `.${ARRAY_ERROR}`
          }

          const message = options.errorMessage(_error)
          set(errors, fullPath, message)
        })
      })
    }
    return {values, errors}
  }
}

export const isRequired = (schema) => {
  const raw = schema.valueOf({isRoot: false})
  const {type, nullable} = raw
  const required = !isEmpty(raw.required)

  if (type === 'string' && raw.minLength) return true
  if (!nullable && required) return true

  return false
}

export const dropDataUrl = (value = '') => {
  if (!isString(value)) return value
  const matches = value.match(/^data:.+\/(.+);base64,(.*)$/)
  return matches && matches[2]
}

const contentSchemasByType = keyBy(ALL_CONTENT, 'type')


export const createDynamicContentSchema = (content) => {
  const schema = {}

  if (isEmpty(content?.type)) return schema

  const {value: valueSchema} = contentSchemasByType[content.type]

  schema.value = valueSchema ? valueSchema.default({}) : baseSchemas.object({}).default({})
  schema.type = baseSchemas.contentType().required()

  if (isEmpty(content.children)) return schema

  schema.children = S.array().items(map(content.children, (child) => baseSchemas.object(createDynamicContentSchema(child))))

  return schema
}

export const createDynamicProductAttributeValueSchema = (productAttributes) => {
  const attributesByType = keyBy(ALL_ATTRIBUTES, 'type')
  if (isEmpty(productAttributes)) return S.array()

  return S.array().items(map(productAttributes, ({type, meta}) => {
    return baseSchemas.optionalObject({
      ...megashopSchemas.attribute,
      value: attributesByType[type].schema(meta),
    }).required(meta?.validation?.required ? ['value'] : [])
  }))
}

export const createDynamicOrderItemAttributeValueSchema = (orderItemAttributes) => {
  const attributesByType = keyBy(ALL_ATTRIBUTES, 'type')
  if (isEmpty(orderItemAttributes)) return S.array()

  return S.array().items(map(orderItemAttributes, ({attribute}) => {
    return baseSchemas.object({
      ...megashopSchemas.orderItemAttribute,
      value: attributesByType[attribute.type].schema(attribute.meta),
    }).required(attribute.meta?.validation?.required ? ['value'] : [])
  }))
}

export const filterEmptyAttributes = (attributes) => {
  return filter(attributes, ({value}) => !isNil(value))
}

export const validateAttributeDefaultValue = (values) => {
  if (!values.defaultValue) return {}
  const attributesByType = keyBy(ALL_ATTRIBUTES, 'type')
  return validator(baseSchemas.object({
    defaultValue: attributesByType[values.type].schema(values.meta),
  }))({defaultValue: values.defaultValue})
}
